::-webkit-input-placeholder
{
	@include standardformPlaceholder;
}

::-moz-placeholder
{
	@include standardformPlaceholder;
}

:-ms-input-placeholder
{
	@include standardformPlaceholder;
}

input:-moz-placeholder
{
	@include standardformPlaceholder;
}

input,
textarea,
select,
option
{
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

	&:focus
	{
		background: #FFF;
		outline-offset: 0;
	}
}

[disabled=disabled],
:disabled
{
	background: #DDD !important;
	border-color: #DDD !important;
	opacity: .5;
}

form
{
	margin: 0;
	padding: 0;

	&.tabbed,
	&.fullwidth
	{
		max-width: none;
	}
}

fieldset
{
	display: block;
	clear: both;
	padding: 0 0 10px;
	border: 0;
}

fieldset.message
{
	margin: 0;
}

fieldset legend
{
	padding: 20px 0 10px;
}

fieldset legend span
{
	font-size: 1.6em;
	font-weight: bold;
}

fieldset legend.label span
{
	font-weight: normal;
}

fieldset.data label,
fieldset.message label,
fieldset.checkbox label
{
	clear: both;
	float: none;
	display: inline-block;
	margin: 0;
	padding: 0 20px .5em 0;
	font-size: 1.6em;
	line-height: 1.55556em;
	text-align: left;
	@include media($tablet)
	{
		@include font-size-16px;
		padding-bottom: .5em;
	}
	@include media($mobile)
	{
		@include font-size-14px;
		padding-bottom: .5em;
	}
}
fieldset.checkbox label
{
	display: block;
}

fieldset label abbr
{
	border: none;
	outline: none;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea,
select
{
	box-sizing: border-box;
	display: block;
	float: left;
	height: auto;
	padding: .7em .8em .6em;
	margin: 0 0 1.5em 0;
	max-width: 100%;

	//font-weight: normal;
	//font-size: 1.6em;
	//line-height: 1.555555556em;
	//color: #001b44;
	//border: 1px solid #999;
	//background: #FFF;
	//letter-spacing: 0.02em;
	//width: 100%;

	@include media($tablet)
	{
		@include font-size-16px;
		padding: .5em .8em;
	}
	@include media($mobile)
	{
		@include font-size-14px;
		padding: .5em .8em;
	}
}

input[type='checkbox']
{
	max-width: none;
}

select.multiple
{
	width: 200px;
	height: 400px;
}

textarea
{
	overflow-y: scroll;
	height: 400px;
	width: 100%;
	padding: 0.5em 0.8em;
	resize: none;
	white-space: pre-line;

	&.rte
	{
		width: 100%;
	}

	&.low
	{
		height: 4.5em;
	}
}

select,
input[type='text'],
input[type='password'],
input[type='tel'],
input[type='mail'],
input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='number'],
textarea
{
	&:hover,
	&:focus,
	&:active
	{
		background: #FFF;
		border: 1px solid $maincolor-Blue-Dark;
		box-shadow: none;
		outline: 1px solid #FFF;
	}
}

fieldset.checkbox label,
label.checkbox
{
	padding: 5px 0;
	line-height: 1;
}

label input,
input[type="checkbox"]
{
	display: inline-block;
	float: none;
	margin: 2px 5px .75em 0;
	padding: 0;
	width: auto !important;
	line-height: 1;
	background: none;
	border: 0;
	outline: 0;
}

fieldset.radio
{
	padding-bottom: 10px !important;

	label
	{
		padding: 5px 0;
		line-height: 1;
	}

	input[type="radio"]
	{
		margin-bottom: 0;
	}
}

fieldset.checkbox
{
	padding-bottom: 10px !important;

	input[type="checkbox"]
	{
		margin-bottom: 0;
	}

	fieldset
	{
		legend
		{
			padding: 10px 0 5px;
		}
	}
}

fieldset.checkboxesInARow label
{
	display: block;
	padding: 0 15px 0 0;
	width: auto;
	font-size: 1.4em;
}

fieldset.checkboxesInARow input
{
	display: inline-block;
	width: 15px;
	height: 15px;
	border: 1px solid #999;
	outline: 0;
}

fieldset.radiobuttonsInARow
{
	label
	{
		display: block;
		float: left;
		clear: none;
		padding: 0 30px 0 0;
		width: auto;
	}

	input[type="radio"]
	{
		clear: none;
	}
}

button.btn,
input.submit,
input[type=submit],
fieldset.submit input,
fieldset.submit button,
a.linkLikeSubmitButton
{
	display: block;
	margin: 10px 0;
	padding: 8px 14px 8px !important;
	display: block;
	clear: both;
	width: auto;
	height: auto;
	color: #FFF;
	background: $maincolor-Red-Divera247;
	border: 1px solid $maincolor-Red-Divera247;
	border-radius: 10px;
	transition: box-shadow .5s;
	font-weight: bold;
	font-size: 1.8em;
	line-height: 1.555555556em;
	cursor: pointer;
	transition: all .247s;

	@include media($tablet)
	{
		@include font-size-16px;
		padding: 10px;
	}
	@include media($mobile)
	{
		@include font-size-14px;
		padding: 7px;
	}
}

button.btn:hover,
input.submit:hover,
input[type=submit]:hover,
fieldset.submit input:hover,
a.linkLikeSubmitButton:hover
{
	cursor: pointer;
	color: $maincolor-Red-Divera247;
	background: #FFF;
}

.form-group.error .help-block
{
	display: block;
}

input[type="text"] + .help-block
input[type="text"] + .error
{
	position: relative;
	top: -20px;
}

.help-block,
div.error:not(.form-group),
label.error:not(.form-group)
{
	margin: 0 0 20px;
	padding: 0;
	clear: left;
	float: left;
	width: auto;
	color: red;
	font-weight: bold;
	font-size: 14px;
	text-align: left;
	background: none;
}

/*
span.error-exclamation
{
	display: inline-block;
	margin: 0 0 0 .25em;
	width: 20px;
	height: 20px;
	background: red;
	color: white;
	border-radius: 3px;
	font-weight: bold;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
}
*/

input.ng-invalid,
input.error,
input.error:hover,
input.error:focus,
select.error,
textarea.error,
textarea.error:hover,
textarea.error:focus,
.error input,
.error textarea
{
	border: 1px solid #e6a5a5 !important;
}

fieldset.multiselectbox
{
	overflow: hidden;
}

fieldset.multiselectbox > div
{
	width: 400px;
	float: left;
}

fieldset.data.multiselectbox select
{
	float: none;
	width: 200px;
	height: 400px;
}

fieldset.multiselectbox select option
{
	font-size: 1em;
}

fieldset.phonenumbers
{
	display: block;
	clear: both;
}

.trigger,
.honey
{
	position: absolute;
	top: -9999px;
	left: -9999px;
}

/* login */
form.login
{
	padding: 0 0 25px;
	border: 0;

	& > fieldset
	{
		position: relative;
		margin-top: 0;
		padding: 0;
		border: 0;

		&.data
		{
			input
			{
				margin-bottom: .5em;
			}

			input[type="checkbox"]
			{
				width: auto;
			}

			input[type="checkbox"] + label
			{
				display: inline-block;
				clear: none;
			}

			.field-login-password,
			.field-login-remember
			{
				clear: both;
				padding-top:5px;
			}
		}

		&.submit
		{
			margin-top: 0;
			padding: 0;

			p
			{
				padding-bottom: 2.4em;
				font-size: 1.2em;
				line-height: 1.5;
			}

			input + a.reset
			{
				display: inline-block;
				float: left;
				font-size: 1.5em;
				line-height: 3em;
				text-decoration: underline;
			}
		}
	}
}

form fieldset.checkbox table
{
	th, td
	{
		input
		{
			margin: 0;
		}

		label
		{
			margin: 0;
			padding: 0;
			font-size: 1em;
		}
	}
}

form fieldset.checkbox-tree table
{
	th, td
	{
		input
		{
			margin: 0 10px 0 0;
			padding: 0;
		}

		label
		{
			margin: 0;
			padding: 0;
			font-size: 1em;
		}

		& > label + fieldset
		{
			padding-top: 20px;
		}

		fieldset label
		{
			padding-bottom: 20px;

			&:last-child
			{
				padding-bottom: 0;
			}
		}

		fieldset
		{
			padding: 0 0 0 4px;

			fieldset
			{
				padding: 0 0 0 24px;
			}

		}

		fieldset + label
		{
			padding-top: 24px;
		}
	}
}

label.styledCheckbox
{
	overflow: hidden;
	display: inline-block;
	padding: 0 20px 0 4px;
	line-height: 1;

	input[type=checkbox]
	{
		position: absolute;
		visibility: hidden;
	}

	span.input-status
	{
		position: relative;
		top: 2px;
		display: block;
		float: left;
		width: 16px;
		height: 16px;
		background: #000;

		&:after
		{
			position: absolute;
			top: 1px;
			left: 1px;
			right: 1px;
			bottom: 1px;
			background: #FFF;
			border: 1px solid #FFF;
			content: "";
		}
	}

	span.input-label
	{
		display: block;
		margin: 0 0 0 24px;
		padding: 0;
		font-size: 16px;
		line-height: 20px;
		color: #000;
	}

	&:hover span.input-status:after
	{
		background: #999;
	}

	input[type=checkbox]:checked + span.input-status:after
	{
		background: #000;
	}
}

label.styledRadio
{
	overflow: hidden;
	display: inline-block;
	padding: 0 20px 0 4px;
	line-height: 1;

	input[type=radio]
	{
		position: absolute;
		visibility: hidden;
	}

	span.input-status
	{
		position: relative;
		top: 2px;
		display: block;
		float: left;
		width: 16px;
		height: 16px;
		background: #000;
		border-radius: 100%;

		&:after
		{
			position: absolute;
			top: 1px;
			left: 1px;
			right: 1px;
			bottom: 1px;
			border-radius: 100%;
			background: #FFF;
			border: 1px solid #FFF;
			content: "";
		}
	}

	span.input-label
	{
		display: block;
		margin: 0 0 0 20px;
		padding: 0;
		font-size: 16px;
		line-height: 20px;
		color: #000;
	}

	&:hover span.input-status:after
	{
		background: #999;
	}

	input[type=radio]:checked + span.input-status:after
	{
		background: #000;
	}
}

.filter
{
	img
	{
		display: inline-block;
		width: 20px;
	}

	.input-wrap
	{
		position: relative;
	}

	a.input-gfx
	{
		position: absolute;
		top: 0;
		right: 10px;
		bottom: 0;
		display: flex;
		align-items: center;

		img
		{
			flex: 1 1 auto;
		}
	}

	.active a.input-gfx i
	{
		transform: rotate(180deg);
	}
}

.search__filter
{
	fieldset.submit
	{
		text-align: right;

		input[type='submit']
		{
			display: inline-block !important;
			float: none;
			margin-top: 0;
			margin-bottom: 20px;
			color: rgba(252, 252, 252, 0.72);
			background: rgba(0, 131, 255, 0.24);
			border: 1px solid rgba(252, 252, 252, 0.48);
			border-radius: 6px;
			letter-spacing: 0.05em;
			font-size: 1.6em;
			font-weight: 500;
		}
	}
}

selectbox
{
	position: relative;
	display: block;
	width: 100%;
}

.selectbox
{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
}

.selectbox__label
{
	display: flex;
	padding: 0 10px 0 0;
	height: 40px;
	align-items: center;
	font-size: 16px;
}

.selectbox__field
{
	position: relative;
	flex: 1 1 auto;
}

.selectbox.opened
{
	.selectbox__field__dropdown
	{
		display: block;
	}

	&:hover
	{
		.selectbox__field__dropdown
		{
			&:before
			{
				position: absolute;
				top: 0;
				right: -1px;
				bottom: -1px;
				left: -1px;
				z-index: -1;
				background: #FFF;
				content: "";
			}
		}

		.selectbox__field__dropdown__list
		{
			border: 1px solid #00081F;
			border-top: 1px solid #FFF;
		}
	}

	&:hover
	{
		.selectbox__field__selection
		{
			border: 1px solid #00081F;
			box-shadow: none;
			outline: 1px solid #FFF;
		}
	}
}

.selectbox__field__selection
{
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 2.6rem;
	padding: .5rem .4rem .3rem;
	border: 1px solid #FFF;
	font-family: inherit;
	font-size: 1rem;
	line-height: 1.5rem;
	color: #000;
	background-color: #FFF;
}

.selectbox__field__selection__value
{
	position: absolute;
	top: 0;
	left: 8px;
	bottom: 0;
	overflow: hidden;
	width: 90%;
	line-height: 40px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.selectbox__field__selection__toggle
{
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	padding: 5px;
	z-index: 9;
	cursor: pointer;
	background: #fff;

	i
	{
		flex: 1 1 auto;
		font-size: 16px;
		color: #000;
	}
}

.selectbox__field__dropdown
{
	position: absolute;
	top: 100%;
	left: 0px;
	right: 0px;
	z-index: 999999;
	margin: -1px 0 0;
	display: none;

	background: #FFF;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, .5);

}

.selectbox__field__dropdown__list
{
	position: relative;

	overflow-y: auto;
	overflow-x: hidden;
	max-height: 250px;
	padding: 0 .5rem;
	background: #FFF;
	border: 1px solid #FFF;

	selectbox__field__dropdown__list__item
	{
		padding: 5px;
		list-style: none;
		font-size: .9em;
		line-height: 1;
		cursor: pointer;
	}
}

.autocomplete
{
	position: relative;
	margin: 0;
}

.autocomplete__input
{
	margin: 0;
	float: none !important;
}

.autocomplete__output
{
	position: absolute;
	top: 80px;
	right: 1px;
	left: 1px;
	z-index: 99999;
	margin: 0 0;
	outline: 1px solid #999;
	max-height: 174px;
	overflow-y: scroll;
}

.autocomplete__output__list
{
	display: block;
	margin: 0;
	padding: 0px;
	background: #FFF;
}

.autocomplete__output__list__item
{
	display: block;
	margin: 0;
	padding: 10px;
	background: #FFF;
	font-size: 14px;
	cursor: pointer;

	&:not(:last-child)
	{
		border-bottom: 1px solid #999;
	}

	&:nth-child(2n)
	{
		background: #F9F9F9;
	}

	&:hover
	{
		background: #F1F1F1;
	}
}

@media (max-width: $mobile){
	body{
		&.form,
		&.report,
		&.index{
			#page-wrap-inner{
				padding-top: 40px;
				#main{
					padding: 0;
				}
			}
			// attention, this will break ucr-menu!
			#ucrNav{
				display: none;
			}
		}
	}
	html.ucr-menu #ucrNav{
		display: block !important;
	}
}