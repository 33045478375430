[class^="grid"]
{
	position: relative;
	display: block;
	clear: both;
	width: 100%;
	
	&:after
	{
		display: table;
		content: " ";
	}
	&:after
	{
		clear: both;
	}
	& > div
	{
		position: relative;
		display:inline-block;
		&.left
		{
			float: left;
			&:first-child
			{
				& > div
				{
					padding-left: 0;
				}
			}
		}
		&.right + .left
		{
			& > div
			{
				padding-left: 0;
			}
		}
		&.right
		{
			float: right;

			& > div
			{
				padding-right: 0;
			}
		}
		& > div
		{
			padding: 0 15px;
		}
	}
	&.equalize
	{
		display: table;
		table-layout: fixed;
		
		& > div
		{
			display: table-cell;
			float: none;
			margin: 0;
			vertical-align: top;
			& > div
			{
				
			}
		}
	}
	&.gutter-10px
	{
		& > div > div
		{
			padding: 0 10px;
		}
	}
}
.grid5050
{
	& > div
	{
		width: 50%;
	}
}
.grid333333
{
	& > div
	{
		box-sizing: content-box;
		width: 30%;
		padding-right: 5%;
		&:last-child
		{
			padding-right: 0 !important;
		}
	}
}
.grid25252525
{
	& > div
	{
		box-sizing: content-box;
		width: 22.5%;
		padding-right: 3.3333333333%;
		& > div
		{
			padding: 0 !important;
		}
		&:last-child
		{
			padding-right: 0 !important;
		}
	}
}
.grid7525
{
	& > div
	{
		box-sizing: content-box;
		width: 75%;
		& + div
		{
			width: 25%;
		}
	}
}
.grid2575
{
	& > div
	{
		width: 25%;
		& + div
		{
			width: 75%;
		}
	}
}
.grid6633
{
	& > div
	{
		width: 66%;
		& + div
		{
			width: 33%;
		}
	}
}
.grid3366
{
	& > div
	{
		width: 33%;
		& + div
		{
			width: 66%;
		}
	}
}
@include media($tablet)
{
	[class^=grid]>div>div
	{
		padding: 0 14px;
	}

	[class^="grid"]
	{
		&.breakOnTablet
		{
			&.grid5050,
			&.grid333333,
			&.grid25252525,
			&.grid7525,
			&.grid2575,
			&.grid6633,
			&.grid3366
			{
				display: block;
				& > div
				{
					display: block;
					padding: 0 0 15px;
					float: none;
					width: 100%;
					& > div
					{
						display: block;
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
}
@include media($mobile)
{
	[class^=grid]>div>div
	{
		padding: 0 7px;
	}

	[class^="grid"]
	{
		&.breakOnMobile
		{
			&.grid5050,
			&.grid333333,
			&.grid7525,
			&.grid2575,
			&.grid6633,
			&.grid3366
			{
				display: block;
				& > div
				{
					display: block;
					padding: 0 0 14px;
					float: none;
					width: 100%;
					& > div
					{
						display: block;
						margin: 0;
						padding: 0;
					}
				}
			}
			&.grid25252525
			{
				& > div
				{
					padding: 0;
					width: 50%;
					&:nth-child(3n)
					{
						clear: both;
					}
					&.left:nth-child(2n)
					{
						float: right;
						padding-right: 0;
						
					}
					& > div
					{
						padding: 0 14px !important;
					}
				}
			}
		}
		
		&.grid333333:not(.breakOnMobile)
		{
			& > div:not(:last-child)
			{
				width: 32%;
				padding-right: 2%;
			}
		}
		
	}
}
.row
{
	position: relative;
	flex-wrap: nowrap;
	flex-flow: row;
	display: flex;
}

.column
{
	flex: 1 1;
	//width: 50%;
}

.row.seperatedByBorder
{
	& > .column:not(:last-child)
	{
		position: relative;
		&:after
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 1px;
			background: #CCC;
			content: " ";
		}
	}
	&.breakOnMobile
	{
		& > .column:not(:last-child):after
		{
			@include media($small-tablet)
			{
				display: none;
			}
		}
	}
	&.breakOnTablet
	{
		& > .column:not(:last-child):after
		{
			@include media($tablet)
			{
				display: none;
			}
		}
	}
}