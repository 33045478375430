.input-icons
{
	position: absolute;
	top: 0px;
	right: 0;
}
	.input-icons__list
	{
		position: relative;
		display: block;
	}
		.input-icons__list__item
		{
			display: inline-block;
			padding: 13px;
			cursor: pointer;
			color: $maincolor-Blue-Dark;
		}
			.input-icons__list__item i
			{
				font-size: 20px;
			}