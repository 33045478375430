*:focus{outline:0;}
a:focus{background:inherit;}

.ng-cloak
{
	visibility: hidden;
}
.spacingTop0
{
	margin-top: 0 !important;
	padding-top: 0 !important;
}
.spacingBottom0
{
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}
a
{
	text-decoration: none;
}
b, strong 
{
	font-weight: bold;
}
i, em 
{
	font-style: italic;
}

	.content ul,
	.content ol
	{

		ul,
		ol,
		li
		{
			font-size: 1em;
		}
		&.flashmessages
		{
			@include font-size-15px;
			margin: 1.55555556em 0;
			padding: .7em;
			border: 1px solid $maincolor-Blue-Dark;
		}

		&.sitemap
		{
			margin-left: 0;
			padding-left:  0;
			ul,ol
			{
				font-size: 1em;
			}

			li
			{
				list-style: none;
			}
		}
	}
	time
	{
		padding: 0 0 5px;
		font-size: 1.5em;
		font-weight: normal;
		line-height: 1.5em;
	}
	figure
	{
		display: block;
		padding: 20px 0;
	}
	figcaption
	{
		padding: 10px 0 0;
		font-weight: 500;
		font-size: 1.5em;
	}
	h1 + figure,
	h2 + figure,
	h3 + figure,
	p + figure
	{
		padding-top: 0;
	}
	a figcaption
	{
		text-align: center;
	}
	a
	{
		cursor: pointer;
		img
		{
			border: 0;
		}
	}
	.table
	{
		display: table;
		width: 100%;
		height: 100%;
	}
		.tablecell
		{
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			text-align: center;
		}
			.tablecellcontent
			{
				display: inline;
			}

div.summary
{
	font-size: 1.8em;
}

form.setStatusForm
{
	padding: 20px 0 0;
}
form.setStatusForm
{
	padding: 0 0 10px;
}
	div.statusList
	{
		display: block;
		padding: 0;
		width: 100%;
		height: auto;
		text-align: center;
	}
		div.statusList div.statusItem
		{
			position: relative;
			display: inline-block;
			float: none;
			margin: 0 0 5px 0;
			padding: 0 5px;
		}
			div.statusList div.statusItem div.circle
			{
				display: table-cell;
				width: 200px;
				height: 200px;
				background: #FFF;
				border: 4px solid #EEE;
				border-radius: 200px;
				box-shadow: 0 0 6px rgba(0,0,0,.4) inset;
				text-align: center;
				vertical-align: middle;
			}
			div.statusList div.statusItem.active div.circle,
			div.statusList div.statusItem div.circle:hover
			{
				box-shadow: 0 0 6px rgba(0,0,0,.8) inset;
			}
			div.statusList div.statusItem div.circle:hover
			{
				cursor: pointer;
			}
				div.statusList div.statusItem div.circle span
				{
					overflow: hidden;
					display: inline;
					font-size: 3em;
					line-height: 1em;
					font-weight: 700;
					//word-break: hyphenate;
					-moz-hyphens: auto;
					-webkit-hyphens: auto;
					hyphens: auto;
				}
					div.statusList div.statusItem div.circle span span.status_title
					{
						display: inline-block;
						margin: 0;
						padding: 1em 0 .5em;
					}
				div.statusList div.statusItem a
				{
					position: relative;
					overflow: hidden;
					display: inline-block;
					margin: 0 0.25em 0;
					padding: 0;
					width: 17.5%;
					height: 1.25em;
					color: rgba(0, 0, 0, 0.2);
					background: none;
					border: 1px solid rgba(0, 0, 0, 0.2);
					border-radius: 8px;
					font-size: 1em;
					line-height: 1.25em;
					font-weight: normal;
					text-decoration: none;
					text-transform: none;
				}
				div.statusList div.statusItem a:hover
				{
					color: rgba(0, 0, 0, 0.4);
					border-color: rgba(0, 0, 0, 0.4);
				}
					div.statusList div.statusItem a i
					{
						margin: 0;
						padding: 0;
						letter-spacing: 1em;
						word-spacing: 1em;
					}
			div.statusList div.statusItem div.tooltip
			{
				position: absolute;
				top: 100%;
				left: 14px;
				right: 14px;
				z-index: 300;
				display: none;
				margin-top: 20px;
				padding: 10px;
				color: #000;
				background: #FFF;
				border-radius: 8px;
				text-shadow: 1px 1px 1px rgba(255,255,255,.2);
				font-size: 1.8em;
			}
			div.statusList div.statusItem:hover div.tooltip
			{
				display: block;
			}
				div.statusList div.statusItem div.tooltip span.arrow
				{
					@include beforeAfter;
					top: -5px;
					left: 50%;
					right: auto;
					z-index: 653;
					margin-left: -6px;
					width: 12px;
					height: 12px;
					transform: rotate(45deg);
				}

div#overlay
{
	position: fixed;
	top: 7em;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255,255,255,0.95);

	div#overlay-Content-Wrap-Outer-1
	{
		z-index: 14;
		display: table;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: middle;

		div#overlay-Content-Wrap-Outer-2
		{
			display: table-cell;
			height: 100%;
			vertical-align: middle;

			div#overlay-Content
			{
				display: inline;
				vertical-align: middle;
				h1
				{
					font-size: 5em;
				}
				h2
				{
					padding-top: 1em;
					font-size: 3em;
				}
			}
		}
	}

	div#overlay-Close 
	{ 
		position: absolute;
		right: 1em;
		top: 1em;
		z-index: 15;
		width: 2em;
		height: 2em;
		color: #FFF;
		background: $maincolor-Grey-Light;
		border-radius: 10px;
		&:hover
		{
			cursor: pointer;
		}
		&:after
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: inline-block;
			margin: 0 ;
			content: "x";
			font-size: 2em;
			line-height: 0.8em;
			text-align: center;
		}
	}
}

@include media($tablet)
{
	h1.likeH2,
	h2
	{
		@include font-size-18px;
	}
	h1.likeH3,
	h2.likeH3,
	h3,
	h4,
	h5,
	h6
	{
		@include font-size-16px;
	}
}

@include media($mobile)
{
	h1
	{
		@include font-size-20px;
	}
	h1.likeH2,
	h2
	{
		@include font-size-16px;
	}
	h1.likeH3,
	h2.likeH3,
	h3,
	h4,
	h5,
	h6
	{
		@include font-size-14px;
	}
}