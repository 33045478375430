$ym-wrapper-max-outer-width: 1400px;
$ym-wrapper-max-inner-width: 1380px;
$color-font-black: black;
$color-grey: #a0a69b;
$color-grey-dark: #54594e;
$color-blue: #00569d;
$color-green: #049c00;
$color-orange: #dd8c00;
$color-red: #d90000;

$screen: 1024px;
$tablet: 960px;
$small-tablet: 768px;
$mobile: 680px;
$mobile-portrait: 320px;

$asideWidth: 20% !default;
$mainWidth: 80% !default;

@mixin font-size-40px
{
	padding-bottom: .3em;
	font-size: 4em;
	line-height: 1.5;
}

@mixin font-size-30px
{
	padding-bottom: 0.4em;
	font-size: 3em;
	line-height: 1.4;
}

@mixin font-size-25px
{
	padding-bottom: 1em;
	font-size: 2.5em;
	line-height: 1.4;
}

@mixin font-size-20px
{
	padding-bottom: 1em;
	font-size: 2em;
	line-height: 1.4;
}

@mixin font-size-18px
{
	padding-bottom: 1em;
	font-size: 1.8em;
	line-height: 1.555555556em;
}

@mixin font-size-16px
{
	padding-bottom: 1em;
	font-size: 1.6em;
	line-height: 1.75em;
}

@mixin font-size-15px
{
	padding-bottom: 1em;
	font-size: 1.5em;
	line-height: 1.75em;
}

@mixin font-size-14px
{
	padding-bottom: 1em;
	font-size: 1.5em;
	line-height: 1.75em;
}

@mixin font-size-13px
{
	padding-bottom: 1.75em;
	font-size: 1.3em;
	line-height: 1.75em;
}

@mixin font-size-12px
{
	padding-bottom: 1.75em;
	font-size: 1.2em;
	line-height: 1.75em;
}

@mixin font-size-11px
{
	padding-bottom: 1.75em;
	font-size: 1.1em;
	line-height: 1.75em;
}

@mixin font-size-10px
{
	padding-bottom: 1.75em;
	font-size: 1.1em;
	line-height: 1.75em;
}

@mixin media($viewportSize) {
	@media screen and (max-width: $viewportSize) {
		@content;
	}
}

@mixin aside($asideWidth) {
	width: $asideWidth;
	float: left;
	padding: 21px 0 0;
}

@mixin main($mainWidth) {
	float: right;
	width: $mainWidth;
	background: white;
}

@mixin beforeAfter {
	position: absolute;
	overflow: hidden;
	display: block;
	content: ".";
	text-indent: -9876px;
}

@mixin placeholderHeaderForm {
	padding-top: 4px;
	color: #FFF;
	opacity: 1;
	font-size: 1em;
	line-height: 1;
}

@mixin btnShowOnHover {
	article:hover {
		@content;
	}
	@at-root {
		@media screen {
			html.touchevents article {
				@content;
			}
		}
	}
}

@mixin beforeAfter
{
	position: absolute;
	overflow: hidden;
	content: ".";
	text-indent: -9876px;
}

@mixin t3SkinButton
{

}


@mixin standardformPlaceholder
{
	color: #999;
}

@mixin standardPseudoclassSelected
{
	color: #000;
	background: none;
	background: transparent;
	border: 0;
	outline: 0;
	box-shadow: none;
	text-shadow: none;
}

@mixin basicButtonSettings {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	cursor: pointer;
	text-decoration: none;
	font-weight: normal;
	font-size: 1.7em;
	line-height: 1em;
}

@mixin textlinkButtonSettings {

}

@mixin btnButtonSettings {

}

@mixin backgroundgradient
{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: block;
	content: " ";
	$experimental-support-for-svg: true;
	@include background-image(linear-gradient(-45deg, rgba(0,10,50,.1) 0%, rgba(0,0,0,0) 50%));
}



@mixin animation($animate...)
{
	$max: length($animate);
	$animations: '';

	@for $i from 1 through $max {
			$animations: #{$animations + nth($animate, $i)};

			@if $i < $max {
					$animations: #{$animations + ", "};
			}
	}
	-webkit-animation: $animations;
	-moz-animation:    $animations;
	-o-animation:      $animations;
	animation:         $animations;
}

@mixin keyframes($animationName)
{
	@-webkit-keyframes #{$animationName} {
			@content;
	}
	@-moz-keyframes #{$animationName} {
			@content;
	}
	@-o-keyframes #{$animationName} {
			@content;
	}
	@keyframes #{$animationName} {
			@content;
	}
}