html.context-webapp
{
	h1
	{
		color: #FFF;
	}

	.content ul,
	.content ol
	{
		&.flashmessages
		{
			padding: .5em 1em 1em;
			background: $maincolor-Blue-Light;
			border-color: $maincolor-Red-Divera247;
			li
			{
				padding-left: 2em;
			}
		}
	}

	a.btn,
	input[type='submit'],
	button[type='submit']
	{
		background: #FFF;
		border: 1px solid #FFF;
		color: $maincolor-Blue-Light;

		&:hover
		{
			background: transparent;
			border-color: #FFF;
			color: #FFF;
		}

		&.red
		{
			color: $maincolor-Red-Divera247;
		}
	}

	span.error-exclamation
	{
		background: white;
		color: $maincolor-Red-Divera247;
	}

	.md-content.md-backend-theme
	{
		color: #222;
	}

	md-content.md-backend-theme a,
	md-content.md-backend-theme a:hover
	{
		color: $maincolor-Blue-Light;
	}

	.btn,
	md-content.md-backend-theme a.btn,
	input[type='submit'],
	button[type='submit']
	{
		color: #FFF;
		border: 1px solid $maincolor-Blue-Light;
		background: $maincolor-Blue-Light;
		&:hover
		{
			color: $maincolor-Blue-Light;
			background: transparent;
			border-color: $maincolor-Blue-Light;
		}

		&.red
		{
			border: 1px solid $maincolor-Red-Divera247;
			background: $maincolor-Red-Divera247;
			&:hover
			{
				color: $maincolor-Red-Divera247;
				background: transparent;
				border-color: $maincolor-Red-Divera247;
			}
		}

		&.gray
		{
			border: 1px solid $maincolor-Grey-Dark;
			background: $maincolor-Grey-Dark;
			&:hover
			{
				color: $maincolor-Grey-Dark;
				background: transparent;
				border-color: $maincolor-Grey-Dark;
			}
		}
	}

	md-menu-item a,
	md-menu-item a:visited,
	md-menu-item .md-button
	{
		color: $maincolor-Blue-Light;
	}
}