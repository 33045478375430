@media screen and (min-width: 780px)
{
	html.context-webapp #page-wrap-inner
	{
		padding-top: 64px;
	}
}

html.context-webapp body.inactive #page-wrap-inner
{
	padding-top: 6px !important;
}

body.monitor
{
	overflow: hidden;
	#page,
	#page-wrap-inner
	{
		height: 100%;
		min-height: 100%;
	}
	#main
	{
		padding: 0 !important;
		max-width: none;
		min-height: inherit;
		height: auto;
		width: auto;
	}
	div.breadcrumbs
	{
		display: none;
	}

	&.calendar
	{
		overflow: auto;
	}
}