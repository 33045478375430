.content
{
	img
	{
		display: block;
		width: 100%;
		height: auto;

		&.circle
		{
			border-radius: 100%;
		}
	}
}