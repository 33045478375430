.slick-slider
{
	width: 100%;
	height: 100%;
	display: block;
	box-sizing: border-box;
	user-select: none;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list
{
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.slick-list:focus
{
	outline: none;
}

.slick-list.dragging
{
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track
{
	position: relative;
	top: 0;
	left: 0;
	display: block;
	height: 100%;
}

.slick-track:before,
.slick-track:after
{
	display: table;
	content: '';
}

.slick-track:after
{
	clear: both;
}

.slick-loading .slick-track
{
	visibility: hidden;
}

.slick-slide
{
	position: relative;
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

.slide__outer,
.slide__inner
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	img
	{
		display: block;
		object-fit: contain;
	}
}

.slide__outer
{
	img
	{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.slide__content
{
	position: relative;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
}

.slide__content__teaser,
.slide__content__text
{
	display: flex;
	flex: 1 1;
	width: 50%;
	height: 100%;
	//align-items: center;
	@include media(800px)
	{
		flex: 0 0 100%;
		width: 100%;
		height: 50%;
	}
}

.slide__content__teaser
{
	overflow: hidden;
	position: relative;
	justify-content: flex-end;
}

.slide__content__teaser__img
{
	position: absolute;
	top: 10%;
	right: 3%;
	bottom: 10%;
	left: 80px;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;

	img
	{
		display: inline-block;
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}

	@include media(800px)
	{
		justify-content: center;
		align-items: flex-end;
		right: 10%;
		left: 10%;
	}
}

.slide__content__text
{
	display: flex;
	flex-direction: column;
	padding: 0 80px 0 1.5%;
	justify-content: center;
	color: #FFF;

	p
	{
		width: 100%;
	}

	p.description
	{
		/*
		padding-bottom: 0.5em;
		font-size: 45px;
		line-height: 1.4;
		text-shadow: 1px 1px 10px black;


		@include media('1280px')
		{
			font-size: 35px;
		}
		@include media($tablet)
		{
			font-size: 25px;
		}
		@include media(800px)
		{
			font-size: 20px;
		}
		@include media(600px)
		{
			font-size: 15px;
		}
		*/
	}
	p.link
	{
		padding-bottom: 0;
		a.btn
		{
			font-size: inherit;
		}
	}

	@include media(800px)
	{
		padding-left: 10%;
		padding-right: 10%;
		justify-content: flex-start;

		p
		{
			text-align: center;
			&.description
			{
				text-shadow: 1px 1px 2px black;
			}
		}
	}
}

[dir='rtl'] .slick-slide
{
	float: right;
}

.slick-slide.slick-loading img
{
	display: none;
}

.slick-slide.dragging img
{
	pointer-events: none;
}

.slick-initialized .slick-slide
{
	display: block;
}

.slick-loading .slick-slide
{
	visibility: hidden;
}

.slick-vertical .slick-slide
{
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden
{
	display: none;
}

$slick-font-path: '' !default;
$slick-font-family: "FontAwesome" !default;
$slick-loader-path: "../../images/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f104" !default;
$slick-next-character: "\f105" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else
	{
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else
	{
		@return url($slick-font-path + $url);
	}
}

/*
.slick-list {
	.slick-loading & {
		background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	}
}
*/

.slick-prev,
.slick-next
{
	position: absolute;
	display: block;
	height: 50px;
	width: 50px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	z-index: 99;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	&:hover, &:focus
	{
		outline: none;
		background: transparent;
		color: transparent;
		&:before
		{
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before
	{
		opacity: $slick-opacity-not-active;
	}
	&:before
	{
		font-family: $slick-font-family;
		font-size: 50px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@include media(800px)
	{
		height: 30px;
		width: 30px;
		&:before
		{
			font-size: 30px;
		}
	}
}

.slick-prev
{
	left: 5px;
	[dir="rtl"] &
	{
		left: auto;
		right: 5px;
	}
	&:before
	{
		content: $slick-prev-character;
		[dir="rtl"] &
		{
			content: $slick-next-character;
		}
	}
}

.slick-next
{
	right: 5px;
	[dir="rtl"] &
	{
		left: 5px;
		right: auto;
	}
	&:before
	{
		content: $slick-next-character;
		[dir="rtl"] &
		{
			content: $slick-prev-character;
		}
	}
}

.content ul.slick-dots
{
	position: absolute;
	bottom: 0;
	z-index: 99;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li
	{
		position: relative;
		display: inline-block;
		margin: 15px 8px;
		padding: 0;
		cursor: pointer;
		color: #FFF;
		&:before
		{
			display: none;
		}
		button
		{
			border: 0;
			background: transparent;
			display: block;
			padding: 5px;
			outline: none;
			color: transparent;
			cursor: pointer;
			line-height: 0px;
			&:hover, &:focus
			{
				outline: none;
			}
			&:before
			{
				display: block;
				content: " ";
				width: 12px;
				height: 12px;
				border-radius: 100%;
				text-align: center;
				background: rgba(255, 255, 255, .5);
				box-shadow: 0 0 3px rgba(0, 0, 0, .4);
			}
		}
		&.slick-active button:before
		{
			background: rgba(255, 255, 255, 1);
			box-shadow: 0 0 3px rgba(0, 0, 0, .8);
		}
	}
}