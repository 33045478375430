body.home
{
	/*
	#page-wrap-inner
	{
		padding-top: 0;
	}
	*/

	ul.imagelist
	{
		margin: 0;
		padding: 60px 0 20px;
		width: 100%;
		max-width: none;
		text-align: center;
		li
		{
			overflow: hidden;
			display: inline-block;
			margin: 0 2%;
			padding: 0;
			width: 20%;
			border-radius: 100%;
			list-style: none;
			img
			{
				display: block;
				width: 100%;
				height: auto;
			}
			&:before
			{
				display: none;
			}
		}
	}
/*
	#main
	{
		margin: 0;
		padding: 0 0 90px;
		max-width: none;
	}
	#main-wrap-inner
	{
		margin: 0;
		padding: 0;
	}
*/
	.content
	{
		div.sectionwrap
		{
			//position: relative;
			section
			{
				//position: relative;
				//z-index: 100;
				//overflow: visible;
				
				div.background
				{
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					content: " ";
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
				}
				/*
				div.sectionbox
				{
					position: relative;
					z-index: 10;
					padding: 0 90px;
					@include media($tablet)
					{
						padding-left: 10px;
						padding-right: 10px;
					}
					@include media($mobile)
					{
						padding-left: 6px;
						padding-right: 6px;
					}
				}
				*/
				&.intro
				{
					div.background
					{

					}
					div.sectionbox
					{
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}
				}
				&.light
				{
					.shareicons
					{
						a
						{
							display: block;
							padding: .5em 0;
							text-decoration: none;
							font-weight: 300;

							i
							{
								display: inline-block;
								width: 1.5em;
								font-size: 1.5em;
								&:before
								{
									margin: 0 !important;
								}
							}
						}
					}
					div.background
					{
						background: #FFF;
					}
					div.sectionbox
					{
						/*
						position: relative;
						margin: 0 auto;
						padding-top: 25px;
						padding-bottom: 25px;
						*/
						//max-width: 1280px;
						ul.imagelist
						{
							max-width: none;
							li
							{
								margin: 0 2%;
								padding: 0;
								&:before
								{
									display: none;
								}
							}
						}
					}
				}
				&.dark
				{
					z-index: 101;
				}
			}
			.parallax
			{
				clear: both;
				&.start .light .sectionbox
				{
					padding-top: 50px;
				}
				section.dark
				{
					color: #FFF;
					background: linear-gradient(#031f38,#04213f);

					.relpos
					{
						position: relative;
						width: 100%;
						max-width: 1280px;
						margin: 0 auto;
						/*
						a.skiplink
						{
							position: absolute;
							top: -25px;
							left: 50%;
							display: block;
							margin: 0 0 0 -25px;
							width: 50px;
							height: 25px;
							background: $maincolor-Blue-Dark;
							border-radius: 50px 50px 0 0;
							text-decoration: none;
							i:before
							{
								position: absolute;
								display: block;
								left: 17px;
								text-align: center;
								color: #FFF;
								font-size: 1.8em;
								line-height: 28px;
							}
						}
						*/
						& > .sectionbox
						{
							overflow: hidden;
							padding: 0;

					
							&.static
							{
								padding: 45px 5%;
							}

							figcaption
							{
								text-align: center;
								margin: 0 auto;
								max-width: 960px;
							}
						}
					}
				}
			}
		}
	}
}
/*
.startslider
{
	position: relative; overflow: hidden; margin: 0; padding: 0;
	margin: 0 0 0px; padding: 0; zoom: 1; 
	box-shadow: 0 0 20px rgba(0,0,0,.75);

	.flex-container a:active,
	a:active,
	.flex-container a:focus,
	a:focus  {outline: none;}
	.slides,
	.flex-control-nav,
	.flex-direction-nav {margin: 0; padding: 0; list-style: none;}

	.flex-viewport, .slides, .slide {height: 100%;}

	.slides > .slide {position: relative; display: none; -webkit-backface-visibility: hidden;}
	
	.slide .text
	{
		display: table;
		width: 100%;
		height: 100%;
		text-align: center;

		p
		{
			display: table-cell;
			padding: 20px !important;
			width: 100%;
			height: 100%;
			vertical-align: middle;
		}
	}
	
	.slide .text h1,
	.slide .text h2,
	.slide .text p,
	.slide .text strong
	{
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 10px;
		max-width: 960px;
		color: #FFF;
		font-family: 'Roboto', sans-serif;
		font-size: 30px;
		font-weight: 700;
	}

	.slide
	{
		&.video
		{
			.mejs-offscreen
			{
				display: none;
			}
			.fixvideo
			{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			.centervideo
			{
				width: 100%;
				height: 100%;
				margin: 0 auto;
			}

			.mejs-container
			{
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		.text h2
		{
			font-size: 30px;
			font-weight: 300;
		}
	}

	.flex-pauseplay span {text-transform: capitalize;}
	.slides:after {content: "\0020"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
	.flex-viewport { -webkit-transition: all 1s ease; -moz-transition: all 1s ease; -o-transition: all 1s ease; transition: all 1s ease; }
	.slides { zoom: 1; }
}

html.touch
{
	body.home .content div.sectionwrap .parallax.start .light .sectionbox { padding-top: 25px }
	body.home .content div.sectionwrap .parallax section.dark { height: auto }
	 body.home .mejs-mediaelement {position: static;}
}
*/
@media screen and (max-width:600px)
{
	body.home .content div.sectionwrap .parallax.start .light .sectionbox { padding-top: 25px }
	body.home .content div.sectionwrap .parallax section.dark { height: auto }
}