.search__result
{

}
	.search__result__item
	{
		margin: 0 0 16px;
	}
	.search__result__item--cluster
	{

	}

	.search__result__item--cluster-root .search__result__item__head
	{
		background: rgba(255, 0, 130, 0.79);
		color: #fff;
	}

	.search__result__item--cluster-version-1 .search__result__item__head
	{
		background: rgba(87, 216, 127, 0.5);
		color: #fff;
	}

	.search__result__item--cluster-version-2 .search__result__item__head
	{
		background: rgb(218, 31, 31);
		color: #fff;
	}

	.search__result__item--cluster-version-3 .search__result__item__head
	{
		background: rgba(255, 102, 180, 0.78);
		color: #fff;
	}

	.search__result__item--user
	{

	}
	.search__result__item--contact
	{

	}
		.search__result__item__head
		{
			display: flex;
			padding: 8px 12px;
			min-height: 40px;
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;
			background: #FFF;
			color: $maincolor-Blue-Dark;
			align-content: center;
			align-items: flex-end;
		}
			.search__result__item__icon
			{
				flex: 0 0 5%;
				font-size: 24px;
			}
			.search__result__item__title
			{
				flex: 0 0 55%;
			}
			.search__result__item__meta
			{
				flex: 0 0 40%;
				//text-align: right;
				//justify-content: flex-end;
				div
				{
					float: right;
				}
				span
				{
					display: inline;
					float: left;
				}
				span + span:before
				{
					content: ", ";
				}
			}
		.search__result__item__content
		{
			padding: 12px;
			//font-size: 15px;
			//line-height: 20px;
			border: 1px solid #FFF;

			h1,h2,h3,p,ul,ol,table
			{
				padding-bottom: 10px;
				font-size: 15px;
			}

			a.btn
			{
				display: inline-block;
				padding: 5px;
				margin: 5px;
				font-size: 1.5em;
			}

			table.standardtable
			{
				//table-layout:auto;
				margin: 0 0 10px;
				width:100%;
				font-size: 1em;
				//border: 0;
				//border-collapse: collapse;

				th, td
				{
					//padding: 5px 0;
					text-align: left;
					vertical-align: top;
				}

				td.actions
				{
					a.btn
					{
						margin-left: 0;
						font-size: 1em;
					}
				}
			}
		}
			.search__result__item__text
			{

			}
			.search__result__item__actions
			{
				display: flex;
				& > div
				{
					flex: 1 1 0;
					&:last-child
					{
						text-align: right;
					}
				}
				a.btn
				{
					margin-bottom: 0;
					margin-right: 0;
				}
			}
