table.standardtable
{
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	caption-side: top;
	margin: 20px 0;
	font-size: 1em;
	line-height: 1.5;
}
table.standardtable table caption
{
	font-variant:small-caps;
}
table.full
{
	width: 100%;
}
table.standardtable table.fixed
{
	table-layout:fixed;
}
	table.standardtable th,
	table.standardtable td
	{
		padding: 0.6em 0.9em;
		text-align: left;
		vertical-align: middle;
		font-size: 1.6em;
		@include media($tablet)
		{
			padding: 0.3em;
			font-size: 1.3em;
		}
		@include media($mobile)
		{
			padding: 0.2em;
			font-size: 1.2em;
		}
	}
	.context-webapp table.standardtable
	{
		table-layout: fixed;
	}
	.context-webapp table.standardtable th,
	.context-webapp table.standardtable td
	{
		word-break: break-word;
	}
	table.standardtable thead th,
	table.standardtable thead th a
	{
		color: #FFF;
		background: #00378a;
		font-weight: 900;
	}
	table.standardtable thead td,
	table.standardtable thead td a
	{
		color: #FFF;
		background: #001A31;
	}
		div.content thead th a,
		div.content thead td a
		{
			font-size: 1em;
			text-decoration: none;
		}
		div.content thead th a:hover,
		div.content thead td a:hover
		{
			color: #FFF;
			text-decoration: underline;
		}
		table.standardtable tbody th
		{
			font-weight: 900;
			border-bottom: 1px solid #00378a;
		}
		table.standardtable tbody td
		{
			font-weight: normal;
			border-bottom: 1px solid #00378a;
		}
		table.standardtable tbody th,
		table.standardtable tbody td,
		table.standardtable tfoot td
		{
			a.btn
			{
				margin: 4px 0;
				padding: 4px 10px;
				font-size: .9em;

				& + .btn
				{
					margin-left: 8px;
					@include media($tablet)
					{
						margin-left: 5px;
					}
				}
			}
		}
		table.standardtable tbody th
		{
			font-size: 2em;
			font-weight: bold;
			padding-left: 6px;

			@include media($tablet)
			{
				font-size: 1.5em;
			}
			@include media($mobile)
			{
				font-size: 1.1em;
			}
		}
		table.standardtable.vertical-border thead th
		{
			border-right: 1px solid #00378a;
			&:first-child
			{
				border-left: 1px solid #00378a;
			}
		}
		table.standardtable.vertical-border tbody td,
		table.standardtable.vertical-border tbody th
		{
			border-right: 1px solid #00378a;
			&:first-child
			{
				border-left: 1px solid #00378a;
			}
		}
	table.standardtable tbody tr:hover th[scope="row"],
	table.standardtable tbody tr:hover tbody th.sub
	{
		background:#f0e8e8;
	}
	table.standardtable.normalized-rows tr td
	{
		padding: 0.8em 0.9em;
	}

@include media($mobile)
{
	table.standardtable.break-mobile
	{
		display: block;
		width: 100%;
		thead
		{
			display: none;
		}
		tbody, tfoot, tr
		{
			display: block;
			width: 100%;
		}
		tbody, tfoot
		{
			tr
			{
				margin: 0 0 10px;
				padding: 0 0 10px;
				border-bottom: 1px solid #00378a;
			}
			td, th
			{
				position: relative;
				display: block;
				padding: 5px 0;
				border: 0 !important;
				text-align: left !important;
				&.actions
				{
					padding: 5px 0 !important;
				}
			}
			td:before
			{
				display: block;
				padding: 5px 0;
				content: attr(data-property);
				font-weight: normal;
			}
			th
			{
				font-size: 1.8em;
			}
			&.show-th-on-mobile th
			{
				display: block;
				font-weight: normal;
				font-size: 1.3em;
			}
		}
	}
}

table caption
{
	.left
	{
		float: left;
		justify-content: flex-start;
		text-align: left;
	}
	.right
	{
		float: right;
		justify-content: flex-end;
		text-align: right;
	}
}

table.standardtable table.standardtable
{
	thead,
	tbody
	{
		th, td
		{
			font-size: 1em;
		}
	}
}
