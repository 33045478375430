.loginbox
{
	&.fixedOnTop
	{
		position: fixed;
		top: 0;
		right: 0;
		z-index: 500;
	}
	& > a
	{
		position: relative;
		overflow: hidden;
		display: block;
		margin: 12px;
		padding: 7px 9px;
		height: 29px;
		box-sizing: border-box;
		color: $maincolor-Red;
		background: #FFF;
		border-radius: 3px;
		font-weight: 700;
		font-size: 14px;
		line-height: 1;
		text-decoration: none;
		
		span
		{
			position: relative;
			top: 1px;
			display: inline-block;
			width: 13px;
			height: 13px;
			margin: 0 5px 0 0;
		}
		em
		{
			font-weight: 700;
			font-size: 15px;
			line-height: 1;
		}
	}
}