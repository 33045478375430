#spinner
{
	display: none;

	&.active
	{
		position: fixed;
		top: 50%;
		left: 50%;
		display: flex;
		margin: -50px 0 0 -100px;
		padding: 10px;
		width: 200px;
		height: 110px;
		color: #FFF;
		text-align: center;
		font-size: 12px;
		z-index: 9999999;
		cursor: pointer;
		background: $maincolor-Red-Divera247;
	}
}

#spinner__content
{
	position: relative;
	flex: 1 1 auto;
	align-items: center;
	justify-items: center;
	overflow: hidden;

	&:before
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 60px;
		background: rgba(255,255,255,.2);
		content: "";
		@include animation('spinnerGfx ease-in 1s infinite');
		animation: spinnerGfx ease-in 1.5s infinite;
	}
}

#spinner__content__gfx
{
	position: relative;
	overflow: hidden;
	height: 60px;
	background: #FFF;
	-webkit-mask-image: url("../../../../images/divera247.svg");
	-webkit-mask-position: center;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-size: contain;
	mask-image: url("../../../../images/divera247.svg");
	mask-position: center;
	mask-repeat: no-repeat;
	mask-size: contain;


	&:before
	{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		background: $maincolor-Red-Divera247;
		content: "";
		@include animation('spinnerGfx ease-in 1s infinite');
		animation: spinnerGfx ease-in 1.5s infinite;
	}
}

#spinner__content__label
{
	padding: 10px 0 0;
}

@include keyframes(spinnerGfx)
{
	0%
	{
		left: 0;
	}
	100%
	{
		left: 100%;
	}
}