.iconsprite
{
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	text-indent: -9876px;
	color: #FFF;
	background: url(../../icons/t3skin.png) no-repeat;

	&.action-move-to-top
	{
		background-position: -270px -36px !important;
	}
	&.action-move-up
	{
		background-position: -288px -36px !important;
	}
	&.action-move-down
	{
		background-position: -198px -36px !important;
	}
	&.action-move-to-bottom
	{
		background-position: -252px -36px !important;
	}
	&.action-selection-delete
	{
		background-position: -36px -54px !important;
	}
	&.action-update
	{
		background-position: -198px -0px !important;
	}
	&.action-view
	{
		background-position: -54px -18px !important;
	}
	&.action-delete
	{
		background-position: -162px -18px !important;
	}
}
a.btn
{
	position: relative;
	margin: 10px 10px 10px 0;
	padding: 8px 14px;
	display: inline-block;
	clear: both;
	width: auto;
	height: auto;
	color: #FFF;
	background: $maincolor-Red-Divera247;
	border: 1px solid $maincolor-Red-Divera247;
	border-radius: 10px;
	transition: all .247s;
	font-weight: bold;
	font-size: 1.8em;
	line-height: 1.555555556em;
	text-decoration: none;
	cursor: pointer;
	&:hover
	{
		background: #FFF;
		color: $maincolor-Red-Divera247;
	}

	&.btn--gray
	{
		background: #b9b9b9;
		border: 1px solid #b9b9b9;
		&:hover
		{
			background: #FFF;
			color: #b9b9b9;
		}
	}

	&.textlink-compined-with-button,
	&.textlink-compined-with-button-action-add-item
	{
		padding: 8px 10px 8px 30px;
		&:before,
		&:before
		{
			@include t3SkinButton;
			@include beforeAfter;
			left: 8px;
			top: 10px;
			background-position: -72px -18px !important;
		}
	}
	&.textlink-compined-with-button.action-edit-item:before
	{
		background-position: -198px 0px !important;
	}

	/*
	& + .btn {
		margin-left: 10px;
	}
	*/

	&.icon
	{
		padding: 7px 10px 5px;
		i
		{
			&:before
			{
				margin: 0;
				width: auto;
			}
		}
		span
		{
			display: none;
		}
	}

	@include media($tablet)
	{
		@include font-size-16px;
		padding-bottom: 8px;
	}
	@include media($mobile)
	{
		@include font-size-14px;
		padding-bottom: 8px;
	}
}

input + .btn,
button + .btn
{
	margin-left: 10px;
}

p .btn
{
	font-size: 1em;
}
	
span.pseudobutton:hover
{
	cursor: pointer;
}

.btn-oninputfield-wrap
{
	float: left;
	width: 0;
	height: 0;

	a
	{
		position: relative;
		left: -20px;
		display: block;
		margin: 5px 0 0 0;
		width: 15px;
		height: 15px;
		background: $maincolor-Blue-Dark;
		border-radius: 100%;
		color: #FFF;
		font-size: 10px;
		line-height: 15px;
		text-align: center;
	}
}